import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { PiClockCountdownLight } from 'react-icons/pi';
import Table from 'elements/Table';
import moment from 'moment';
import 'moment/locale/hu';
import { HiOutlineChevronRight } from 'react-icons/hi';

import Graph from 'elements/Graph';
import DataLister from 'elements/DataLister';
import MPTIACalculator from 'components/MPTIACalculator';
import PageTitle, { PageTitleMenuList, PageTitleSubMenuItem, PageTitleBreadcrumbItem } from 'elements/PageTitle';
import LegacyDownload from 'elements/LegacyDownload';
import { SubFooter, SubFooterButtonItem } from 'components/Footer';
import { Input, Result, calculate, _export } from 'pages/CalculatorPage';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { convertToLegacyDate } from 'utils/GenericUtils';
import { useWebsite } from 'providers/WebsiteContextProvider';
import usePrevious from 'hooks/usePrevious';
import { getWebsite } from 'utils/WebsiteUtils';

export const relatedFundMenu = (relatedFunds, className) => {
    if (relatedFunds && relatedFunds.length > 0)
        return (
            <div className={'' + className}>
                <PageTitleMenuList stayRow>
                    {relatedFunds.map((item) => (<PageTitleSubMenuItem key={item.id} data={{ title: getValueFromParameterMap(item, 'label'), fullPath: getValueFromParameterMap(item, 'page') }} />))}
                </PageTitleMenuList>
            </div>
        );
    return null;
};

export default function FundPage(props) {
    const [page, setPage] = useState(null);
    // const prevPage = usePrevious(page);

    const { setHeaderDark } = useWebsite();

    useEffect(() => { setHeaderDark(); }, []);

    const _data = {
        name: getValueFromParameterMap(getValueFromParameterMap(page, 'fund'), 'name'),
        groupName: getValueFromParameterMap(getValueFromParameterMap(page, 'fund'), 'group-name'),
        description: getValueFromParameterMap(getValueFromParameterMap(page, 'fund'), 'description'),
        relatedFunds: getValueFromParameterMap(getValueFromParameterMap(page, 'fund'), 'related-funds') && getValueFromParameterMap(getValueFromParameterMap(page, 'fund'), 'related-funds').contentList,
        uniqueContentTitle: getValueFromParameterMap(getValueFromParameterMap(page, 'fund'), 'unique-content-title'),
        uniqueContentContent: getValueFromParameterMap(getValueFromParameterMap(page, 'fund'), 'unique-content-content'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page); }} className='w-full flex flex-col items-center'>

            <PageTitle className='flex-1' title={_data.name} titleClassName='' content={_data.description} breadcrumb customBreadcrumbChildren={<PageTitleBreadcrumbItem data={{ fullPath: '/befektetesi-alapok', title: '‹ Vissza' }} />} titleChildren={relatedFundMenu(_data.relatedFunds, 'hidden xl:flex pt-6')} bottomChildren={relatedFundMenu(_data.relatedFunds, 'flex xl:hidden')} />

            {page && <DataGrid data={getValueFromParameterMap(page, 'fund')} settings={getValueFromParameterMap(page, 'settings')} />}

            <SubFooter>
                {/* <SubFooterButtonItem title='Befektetési alapok' label='Ugrás a Befektetési Alapokhoz' path='/befektetesi-alapok' /> */}
                <SubFooterButtonItem title='Hozamkalkulátor' label='Ugrás a Hozamkalkulátorhoz' path='/befektetesi-alapok/hozamkalkulator' />
                <SubFooterButtonItem title='Fogalomtár' label='Ugrás a Fogalomtárhoz' path='/befektetesi-alapok/fogalomtar' />
                <SubFooterButtonItem title='Dokumentumok' label='Ugrás a Dokumentumokhoz' path='/kozzetetelek/befektetesi-alapok-dokumentumai' />
                {/* <FastFundSelect className='flex-1' /> */}
            </SubFooter>

        </NBossCMSPage>
    );
}

export function DataGrid(props) {
    const abortRef = useRef(null);

    const [data, setData] = useState(null);
    const prevData = usePrevious(data);

    const [rateDataList, setRateDataList] = useState([]);
    const [rateDataSum, setRateDataSum] = useState(null);

    const _data = {
        name: getValueFromParameterMap(data, 'name'),
        uniqueContentTitle: getValueFromParameterMap(data, 'unique-content-title'),
        uniqueContentContent: getValueFromParameterMap(data, 'unique-content-content'),
        currency: getValueFromParameterMap(data, 'currency'),
        dateCalculator: getValueFromParameterMap(data, 'date-calculator'),
        risk: getValueFromParameterMap(data, 'risk'),
        recommendedInvestmentPeriod: getValueFromParameterMap(data, 'recommended-investment-period'),
        legacyFundId: getValueFromParameterMap(data, 'legacy-fund-id'),
        legacyFundPassword: getValueFromParameterMap(data, 'legacy-fund-password'),
        legacyFolderId: getValueFromParameterMap(data, 'legacy-folder-id'),
        hideRateGraph: getValueFromParameterMap(data, 'hide-rate-graph'),
        hideYearlyPerformance: getValueFromParameterMap(data, 'hide-yearly-performance'),
        hideCalculator: getValueFromParameterMap(data, 'hide-calculator'),
    }

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        // if (prevData) console.log('Prev: ' + prevData.id);
        // if (data) console.log('Curr: ' + data.id);

        if (data && (!prevData || (data.id != prevData.id))) {

            if (abortRef.current != null) abortRef.current.abort();
            abortRef.current = new AbortController();

            const url = 'https://www.diofaalapkezelo.hu/backings/rateControl/getRateDatas'
            const body = { 'input': { 'portfolioId': _data.legacyFundId, 'password': _data.legacyFundPassword, 'dateFrom': 'Jan 1, 2000 00:00:00 AM', 'dateTo': convertToLegacyDate(new Date()) } }

            axios.post(url, body, { signal: abortRef.current.signal }).then(function (response) {

                abortRef.current = null;

                if (response.data) {
                    setRateDataSum(response.data.result.pop().sumBlock);
                    setRateDataList(response.data.result);
                }

            }).catch(function (error) {
                console.log(error);
            });

        }
    }, [data]);

    // useEffect(() => { console.log(rateDataSum); }, [rateDataSum]);
    // useEffect(() => { console.log(rateDataList); }, [rateDataList]);

    return (
        <div className='w-full bg-neutral-50 flex flex-col items-center px-site-mobile md:pb-16 text-primary'>

            <div className='w-full max-w-boxed grid grid-cols-1 md:grid-cols-2 floating-card-grid-gap page-title-content-offset'>

                {/* <div className='flex flex-col floating-card-grid-gap'>
                    <div className='floating-card'><DataSummary data={props.data} rateDataList={rateDataList} rateDataSum={rateDataSum} /></div>
                    {!props.excerpt && <div className='floating-card'><DurationIndicator data={props.data} /></div>}
                </div> */}

                <div className={'floating-card flex flex-col ' + ((_data.risk || _data.recommendedInvestmentPeriod) ? '' : 'col-span-full')}><DataSummary data={props.data} rateDataList={rateDataList} rateDataSum={rateDataSum} /></div>

                {(_data.risk || _data.recommendedInvestmentPeriod) && <div className='flex flex-col floating-card-grid-gap'>
                    {_data.risk && <div className='floating-card flex-1'><RiskIndicator data={props.data} /></div>}
                    {_data.recommendedInvestmentPeriod && <div className='floating-card'><DurationIndicator data={props.data} /></div>}
                </div>}

                {!props.excerpt && <>

                    {(rateDataList && rateDataList.length > 0) && <div className='col-span-full floating-card'>
                        <RateTable data={props.data} rateDataList={rateDataList} rateDataSum={rateDataSum} currency={_data.currency} />
                    </div>}

                    {!_data.hideRateGraph && <div className='col-span-full floating-card'>
                        <RateGraph data={props.data} rateDataList={rateDataList} rateDataSum={rateDataSum} currency={_data.currency} />
                    </div>}

                    {/* {!_data.hideCalculator && <div className='col-span-full floating-card-overflow'><RateCalculator data={props.data} /></div>} */}

                    {_data.dateCalculator && <div className='col-span-full floating-card-overflow'><MPTIADateCalculator /></div>}

                    {!_data.hideYearlyPerformance && <div className='floating-card'><YearlyPerformanceTable data={props.data} /></div>}

                    <div className={'flex flex-col floating-card-grid-gap ' + (_data.hideYearlyPerformance ? 'col-span-full' : '')} >
                        <div className='floating-card'><DistibutorList data={props.data} /></div>
                        {_data.legacyFolderId && <div className='floating-card'><DocumentDownload data={props.data} /></div>}
                    </div>

                    <div className='col-span-full floating-card'><TextContent title={'Fontos információ'} content={getValueFromParameterMap(props.settings, 'disclaimer')} /></div>

                    {(_data.uniqueContentContent) && <div className='col-span-full floating-card'><TextContent title={_data.uniqueContentTitle} content={_data.uniqueContentContent} /></div>}

                </>}

            </div>

        </div>
    );
}

export function DataSummary(props) {
    const _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        key: getValueFromParameterMap(props.data, 'key'),
        description: getValueFromParameterMap(props.data, 'description'),
        risk: getValueFromParameterMap(props.data, 'risk'),
        disclaimer: getValueFromParameterMap(props.data, 'disclaimer'),
        distibutorList: getValueFromParameterMap(props.data, 'distibutor-list'),
        isin: getValueFromParameterMap(props.data, 'isin'),
        benchmark: getValueFromParameterMap(props.data, 'benchmark'),
        class: getValueFromParameterMap(props.data, 'class'),
        category: getValueFromParameterMap(props.data, 'category'),
        type: getValueFromParameterMap(props.data, 'type'),
        customType: getValueFromParameterMap(props.data, 'custom-type'),
        currency: getValueFromParameterMap(props.data, 'currency'),
        geographic: getValueFromParameterMap(props.data, 'geographic'),
        recommendedInvestmentPeriod: getValueFromParameterMap(props.data, 'recommended-investment-period'),
        accountingDayBuy: getValueFromParameterMap(props.data, 'accounting-day-buy'),
        accountingDaySell: getValueFromParameterMap(props.data, 'accounting-day-sell'),
        hideRateGraph: getValueFromParameterMap(props.data, 'hide-rate-graph'),
    }

    // const dataConfig = [
    //     { 'label': 'Alap besorolása', 'value': _data.class },
    //     { 'label': 'ISIN kód', 'value': _data.isin },
    //     { 'label': 'Nettó eszközérték', 'value': (props.rateDataList && props.rateDataList.length > 0) && props.rateDataList[props.rateDataList.length - 1].netto, 'config': { 'type': 'currency', 'currency': getValueFromParameterMap(_data.currency, 'short-label'), 'displayLoading': true } },
    //     { 'label': 'Devizanem', 'value': getValueFromParameterMap(_data.currency, 'short-label') },
    //     { 'label': 'Árfolyam', 'value': props.rateDataSum && props.rateDataSum.closeRate, 'config': { 'type': 'currency', 'currency': getValueFromParameterMap(_data.currency, 'short-label'), 'displayLoading': true } },
    //     { 'label': 'Benchmark', 'value': _data.benchmark },
    //     { 'label': 'Vételi megbízások elszámolása', 'value': _data.accountingDayBuy },
    //     { 'label': 'Visszaváltási megbízások elszámolása', 'value': _data.accountingDaySell },
    //     { 'label': 'Kategória', 'value': _data.customType ? _data.customType : getValueFromParameterMap(_data.type, 'label') },
    //     { 'label': 'Földrajzi kitettség', 'value': _data.geographic },
    // ];

    const getDataConfig = () => {
        let dataConfig = [];

        dataConfig.push({ 'label': 'Alap besorolása', 'value': _data.class });
        dataConfig.push({ 'label': 'ISIN kód', 'value': _data.isin });
        if (!_data.hideRateGraph) dataConfig.push({ 'label': 'Nettó eszközérték', 'value': (props.rateDataList && props.rateDataList.length > 0) && props.rateDataList[props.rateDataList.length - 1].netto, 'config': { 'type': 'currency', 'currency': getValueFromParameterMap(_data.currency, 'short-label'), 'displayLoading': true } });
        dataConfig.push({ 'label': 'Devizanem', 'value': getValueFromParameterMap(_data.currency, 'short-label') });
        if (!_data.hideRateGraph) dataConfig.push({ 'label': 'Árfolyam', 'value': props.rateDataSum && props.rateDataSum.closeRate, 'config': { 'type': 'currency', 'currency': getValueFromParameterMap(_data.currency, 'short-label'), 'displayLoading': true } });
        if (_data.benchmark) dataConfig.push({ 'label': 'Benchmark', 'value': _data.benchmark });
        dataConfig.push({ 'label': 'Vételi megbízások elszámolása', 'value': _data.accountingDayBuy });
        dataConfig.push({ 'label': 'Visszaváltási megbízások elszámolása', 'value': _data.accountingDaySell });
        dataConfig.push({ 'label': 'Kategória', 'value': _data.customType ? _data.customType : getValueFromParameterMap(_data.type, 'label') });
        if (_data.geographic) dataConfig.push({ 'label': 'Földrajzi kitettség', 'value': _data.geographic });

        return dataConfig;
    };

    return (
        <DataLister className='flex-1' data={getDataConfig()} />
    );
}

export function RiskIndicator(props) {
    const _data = {
        risk: getValueFromParameterMap(props.data, 'risk'),
    }

    const riskDescription = [
        { 'description': 'Az Alap Kiemelt Befektetői Információk tájékoztatóban szereplő hozam/kockázati mutatója 7-es skálán 1-es, azonban a skálán a legalacsonyabb szint sem jelent kockázatmentes befektetést. A feltüntetett kockázati kategória nem feltétlenül marad változatlan, és idővel módosulhat.' },
        { 'description': 'Az Alap összesített kockázati mutatója 7-es skálán 2-es, amely alacsony kockázati osztály, a jövőbeli teljesítményből eredő potenciális veszteségeket alacsony szintre sorolja. Ez azt jelenti, hogy a rossz piaci feltételek egyáltalán nem valószínű, hogy hatással lesznek azon képességünkre, hogy kifizessük Önt. Az összesített kockázati mutató iránymutatást ad e termék más termékekhez viszonyított kockázati szintjéről. A feltüntetett mutató idővel módosulhat.' },
        { 'description': 'Az Alap Kiemelt Befektetői Információk tájékoztatóban szereplő hozam/kockázati mutatója 7-es skálán 3-as, azonban a skálán a legalacsonyabb szint sem jelent kockázatmentes befektetést. A feltüntetett kockázati kategória nem feltétlenül marad változatlan, és idővel módosulhat.' },
        { 'description': 'Az Alap Kiemelt Befektetői Információk tájékoztatóban szereplő hozam/kockázati mutatója 7-es skálán 4-es, azonban a skálán a legalacsonyabb szint sem jelent kockázatmentes befektetést. A feltüntetett kockázati kategória nem feltétlenül marad változatlan, és idővel módosulhat.' },
        { 'description': 'Az Alap Kiemelt Befektetői Információk tájékoztatóban szereplő hozam/kockázati mutatója 7-es skálán 5-ös, azonban a skálán a legalacsonyabb szint sem jelent kockázatmentes befektetést. A feltüntetett kockázati kategória nem feltétlenül marad változatlan, és idővel módosulhat.' },
        { 'description': 'Az Alap Kiemelt Befektetői Információkban (KIID) szereplő hozam/kockázati mutatója 7-es skálán 6-os, azonban a skálán a legalacsonyabb szint sem jelent kockázatmentes befektetést. A feltüntetett kockázati kategória nem feltétlenül marad változatlan, és idővel módosulhat.' },
        { 'description': 'Az Alap Kiemelt Befektetői Információkban (KIID) szereplő hozam/kockázati mutatója 7-es skálán 7-es, azonban a skálán a legalacsonyabb szint sem jelent kockázatmentes befektetést. A feltüntetett kockázati kategória nem feltétlenül marad változatlan, és idővel módosulhat.' },
    ];

    if (_data.risk)
        return (
            <div className='flex flex-col gap-6 floating-card-padding' >

                <div className='floating-card-title'>Kockázat</div>

                <div className='w-full flex flex-row'>
                    {riskDescription.map((item, index) => (<div key={index} className={'flex-1 flex flex-col gap-2 items-center ' + (item.strong ? '' : '')}>
                        <div className='text-xxs whitespace-nowrap'>{(index === 0 || index === riskDescription.length - 1) ? (index === 0 ? 'Nagyon alacsony' : 'Nagyon magas') : <span>&nbsp;</span>} </div>
                        <div className='w-full h-[5px] bg-neutral-100'>
                            {index < _data.risk && <div className={'h-full bg-primary ' + (index === _data.risk - 1 ? 'w-[50%]' : 'w-full')} />}
                        </div>
                        <div className={'px-1 py-0.5 ' + (index === _data.risk - 1 ? 'font-bold border-b-2 border-primary' : '')}>{index + 1}</div>
                    </div>))}
                </div>

                <div className='text-xs'>{riskDescription[_data.risk - 1].description}</div>

            </div>
        );
    else
        return null;
}

export function DurationIndicator(props) {
    const _data = {
        recommendedInvestmentPeriod: getValueFromParameterMap(props.data, 'recommended-investment-period'),
    }

    if (_data.recommendedInvestmentPeriod)
        return (
            <div className='flex flex-col xl:flex-row justify-between gap-2 xl:gap-6 items-center floating-card-padding'>

                <div className='floating-card-title'>Javasolt befektetési időtáv:</div>

                <div className='flex flex-row items-center gap-2'>
                    <div className='font-bold xl:font-semibold text-xl 2xl:text-2xl'>{_data.recommendedInvestmentPeriod} év</div>
                    <PiClockCountdownLight className='text-2xl' />
                </div>

            </div>
        );
    else
        return null;
}

export function RateGraph(props) {
    // const [data, setData] = useState([]);

    // useEffect(() => {
    //     if (data) setData(formatData(props.rateDataList));
    // }, [props.rateDataList]);

    // const formatData = (data) => {
    //     if (data) {
    //         let _data = [];
    //         for (let i = 0; i < data.length; i++) {
    //             let _date = new Date(data[i].date);
    //             data[i].time = _date.getTime();
    //             _data.push(data[i]);
    //         }
    //         return _data;
    //     }
    //     return [];
    // };

    return (
        <div className='flex flex-col gap-8 floating-card-padding'>

            <div className='floating-card-title'>Az alap árfolyamának alakulása</div>

            <div className='w-full h-[320px]'>

                {(props.rateDataList && props.rateDataList.length > 0) && <Graph data={props.rateDataList} currency={getValueFromParameterMap(props.currency, 'short-label')} />}

            </div>

        </div>
    );
}

export function YearlyPerformanceTable(props) {
    const _data = {
        fundClosedYearPerformance: getValueFromParameterMap(props.data, 'fund-closed-year-performance') && getValueFromParameterMap(props.data, 'fund-closed-year-performance').contentList,
        hideReferenceIndex: getValueFromParameterMap(props.data, 'hide-reference-index'),
    }

    const showReference = (data) => {
        if (_data.hideReferenceIndex)
            return false;
        for (let i = 0; i < data.length; i++) {
            let reference = getValueFromParameterMap(_data.fundClosedYearPerformance[i], 'reference')
            if (reference && reference !== '' && reference !== '-')
                return true;
        }
        return false;
    };

    const getTableData = (data) => {
        let list = [];
        if (data) {
            for (let i = 0; i < _data.fundClosedYearPerformance.length; i++) {
                list.push({
                    'year': getValueFromParameterMap(_data.fundClosedYearPerformance[i], 'year'),
                    'base': getValueFromParameterMap(_data.fundClosedYearPerformance[i], 'base') + ' %',
                    'reference': getValueFromParameterMap(_data.fundClosedYearPerformance[i], 'reference') + ' %',
                });
            }
        }
        list.reverse();
        return list;
    };

    const getTableFields = (data) => {
        let fileds = [{ 'label': 'Év', 'key': 'year' }, { 'label': 'Alap', 'key': 'base' },];
        if (showReference(data))
            fileds.push({ 'label': 'Ref. Index', 'key': 'reference' });
        return fileds;
    };

    return (
        <div className='flex flex-col'>

            <div className='floating-card-title floating-card-padding'>Lezárt naptári évek teljesítménye</div>

            <Table data={{ 'config': { 'fields': getTableFields(_data.fundClosedYearPerformance), 'reverseBackground': true }, 'list': getTableData(_data.fundClosedYearPerformance) }} />

        </div>
    );
}

export function DistibutorList(props) {
    const _data = {
        distibutorList: getValueFromParameterMap(props.data, 'distibutor-list') && getValueFromParameterMap(props.data, 'distibutor-list').reverse(),
    }

    return (
        <div className='flex flex-row items-center gap-8 floating-card-padding' >

            <div className='floating-card-title'>Forgalmazási helyek</div>

            <div className='flex flex-row items-center gap-6'>
                {_data.distibutorList && _data.distibutorList.map((item, index) => <a key={index} href={getValueFromParameterMap(item, 'link')} target='_blank' className='group' alt={getValueFromParameterMap(item, 'name')}>
                    <img className='max-h-12 max-w-32 group-hover:grayscale transition' alt={getValueFromParameterMap(item, 'name')} src={getValueFromParameterMap(item, 'logo').downloadUrl} />
                </a>)}
            </div>

        </div>
    );
}

export function DocumentDownload(props) {
    const _data = {
        legacyFolderId: getValueFromParameterMap(props.data, 'legacy-folder-id'),
    }

    if (_data.legacyFolderId)
        return (
            <div className='flex flex-col gap-8 floating-card-padding'>

                <div className='floating-card-title'>Letöltések</div>

                <LegacyDownload folderId={_data.legacyFolderId} />

            </div>
        );
    else
        return null;
}

export function TextContent(props) {
    const _data = {
        legacyFolderId: getValueFromParameterMap(props.data, 'legacy-folder-id'),
    }

    return (
        <div className='flex flex-col gap-8 floating-card-padding'>

            {props.title && <div className='floating-card-title'>{props.title}</div>}

            <div className='w-full text-xs md:text-sm paragraph-gap leading-relaxed content-text' dangerouslySetInnerHTML={{ __html: props.content }} />

        </div>
    );
}

export function MPTIADateCalculator(props) {
    const _data = {
        legacyFolderId: getValueFromParameterMap(props.data, 'legacy-folder-id'),
    }

    return (
        <div className='flex flex-col gap-8 floating-card-padding'>

            <div className='w-full flex flex-col gap-2'>
                <div className='floating-card-title'>Online visszaváltási dátum kalkulátor</div>
                <div className='text-sm'>A kalkulátor figyelembe veszi a munkaszüneti napokat is, így a 130 munkanapos határidővel napra pontosan kalkulálhat. A 2025. évben esedékes visszaváltások időpontjai tájékoztató jellegűek, mivel a 2025. évi munkaszüneti napokat meghatározó rendelet még nem került kihirdetésre.</div>
            </div>

            <MPTIACalculator />

        </div>
    );
}

export function RateTable(props) {
    const _data = {
        // legacyFundId: getValueFromParameterMap(props.data, 'legacy-fund-id'),
        // legacyFundPassword: getValueFromParameterMap(props.data, 'legacy-fund-password'),
    }

    const getTableFields = (data) => {
        let fileds = [
            { 'label': ' ', 'key': 'empty' },
            { 'label': '3 havi', 'key': 'month3', 'center': true },
            { 'label': '6 havi', 'key': 'month6', 'center': true },
            { 'label': '1 éves *', 'key': 'year1', 'center': true },
            { 'label': '3 éves *', 'key': 'year3', 'center': true },
            { 'label': '5 éves *', 'key': 'year5', 'center': true },
            { 'label': '10 éves *', 'key': 'year10', 'center': true },
            { 'label': 'Év eleje óta', 'key': 'yearstart', 'center': true },
            { 'label': 'Indulástól', 'key': 'fromstart', 'center': true },
        ];
        return fileds;
    };

    const getTableData = (data) => {
        let list = [{
            'empty': 'Hozam',
            'month3': getValueByManipulation(3, 'months'),
            'month6': getValueByManipulation(6, 'months'),
            'year1': getValueByManipulation(1, 'years'),
            'year3': getValueByManipulation(3, 'years'),
            'year5': getValueByManipulation(5, 'years'),
            'year10': getValueByManipulation(10, 'years'),
            'yearstart': getFromYearStart(),
            'fromstart': getFromStart(),
        }];

        return list;
    };

    const getNowDate = () => {
        const dateWithoutTime = new Date();
        dateWithoutTime.setHours(0, 0, 0, 0);
        return dateWithoutTime;
    };

    const getValueByManipulation = (count, dateManipulationType) => {
        const now = new Date(props.rateDataList[props.rateDataList.length - 1].date);

        // console.log('---');
        // console.log('Count: ' + count + ', date manipulation: ' + dateManipulationType);
        let m_ago = moment(now);
        // console.log('Now: ' + m_ago.format('YYYY.MM.DD'));

        m_ago.subtract(count, dateManipulationType);
        const orig_m_ago = m_ago.clone();


        let m_finalDateAgo = null;
        let isAvailable = _getIndexByDate(m_ago);
        let isLastDayOfMonth = _isLastDayOfMonth(moment(now));

        if (isLastDayOfMonth) {
            // console.log('Is last day of month.');
            m_ago.endOf('month');
            // console.log('Ago modified to last day of ago month: ' + m_ago.format('YYYY.MM.DD'))
        }

        if (isAvailable > -1) {
            m_finalDateAgo = m_ago.clone();
            // console.log('Exact ago date: ' + m_finalDateAgo.format('YYYY.MM.DD'));
        } else {
            // console.log('Exact ago date NOT found.');

            const backwardDate = _getBackwardByDayLimit(m_ago, 5/* utólag kiderült tökmindegy */);

            if (backwardDate) {
                m_finalDateAgo = backwardDate;
            } else {
                // console.log('Backward date NOT found.');
            }
        }

        // console.log('-');

        if (m_finalDateAgo) {
            const array = getArrayBetweenTwoDates(m_finalDateAgo.toDate(), now);
            const calculated = calculateByArray(array, m_finalDateAgo.toDate(), now, orig_m_ago.toDate());
            return roundAndFormat(calculated);
        } else if (props.rateDataSum) {
            roundAndFormat({ value: props.rateDataSum.valuePercent2 / 100 });
        }

        return null;

    };

    const _getIndexByDate = (m_date) => {
        let index = -1;

        for (let i = 0; i < props.rateDataList.length; i++) {
            const dateOfRate = new Date(props.rateDataList[i].date);
            const m_dateOfRate = moment(dateOfRate);
            if (m_dateOfRate.isSame(m_date, 'd'))
                index = i;
        }

        return index;
    };

    const _isLastDayOfMonth = (m_date) => {
        let nextDay = m_date.clone();
        nextDay.add(1, 'd');

        if (m_date.isSame(nextDay, 'M'))
            return false;
        else
            return true;
    };

    const _getBackwardByDayLimit = (m_from, dayLimit) => {
        let dayRemaining = dayLimit;

        while (dayRemaining > 0) {
            m_from.subtract(1, 'd');

            if (_getIndexByDate(m_from) > -1) {
                // console.log('Found backward date, day count remaining backwards: ' + dayRemaining + ' - ' + m_from.format('YYYY.MM.DD'));
                return m_from;
            } else {
                dayRemaining = dayRemaining - 1;
            }
        }

        return null;
    };

    const getFromStart = () => {
        return (props.rateDataSum && props.rateDataSum.valuePercent2) ? roundAndFormat({ value: props.rateDataSum.valuePercent2 / 100 }) : null;
    };

    const getFromYearStart = () => {
        const now = new Date(props.rateDataList[props.rateDataList.length - 1].date);
        const yearStartDate = new Date(getNowDate().getFullYear(), 0, 1);

        const m_ago = moment(new Date(getNowDate().getFullYear(), 0, 1));

        let m_finalDateAgo = null;
        let isAvailable = _getIndexByDate(m_ago);

        if (isAvailable > -1) {
            m_finalDateAgo = m_ago;
            // console.log('Exact ago date: ' + m_finalDateAgo.format('YYYY.MM.DD'));
        } else {
            // console.log('Exact ago date NOT found.');

            const backwardDate = _getBackwardByDayLimit(m_ago, 5/* utólag kiderült tökmindegy */);

            if (backwardDate) {
                m_finalDateAgo = backwardDate;
            } else {
                // console.log('Backward date NOT found.');
            }
        }

        if (m_finalDateAgo) {
            const calculated = calculateByArray(getArrayBetweenTwoDates(m_finalDateAgo.toDate(), now), m_finalDateAgo.toDate(), now);
            return roundAndFormat(calculated);
        }

        return null;
    };

    const roundAndFormat = (obj) => {
        const rounded = (obj && obj.value) ? Math.round(((obj.value * 100) + Number.EPSILON) * 10) / 10 + '%' : null;

        // console.log('Before rounding: ' + num + ', after round: ' + rounded)
        // console.log(obj.meta);

        return rounded /*+ '<br><div class="flex flex-col bg-neutal-200 rounded p-1 text-[10px] font-bold">' +

            (obj.meta && obj.meta.from ? '<div>D. - Kezd.: ' + moment(obj.meta.from).format('YYYY.MM.DD') + '</div>' : '') +
            (obj.meta && obj.meta.firstValue ? '<div>Ért. - Kezd.: ' + obj.meta.firstValue + '</div>' : '') +
            (obj.meta && obj.meta.to ? '<div>D. - Vég: ' + moment(obj.meta.to).format('YYYY.MM.DD') + '</div>' : '') +
            (obj.meta && obj.meta.lastValue ? '<div>Ért. - Vég: ' + obj.meta.lastValue + '</div>' : '') +

            '</div>'*/;
    };

    const getArrayBetweenTwoDates = (from, to) => {
        let arr = [];

        const _from = moment(from);
        const _to = moment(to);

        // console.log('Get array between: ' + _from.format('YYYY.MM.DD') + ', to: ' + _to.format('YYYY.MM.DD'));

        for (let i = 0; i < props.rateDataList.length; i++) {
            const m_itemDate = moment(props.rateDataList[i].date);
            if (m_itemDate.isSameOrAfter(_from, 'days'))
                arr.push(props.rateDataList[i]);
        }

        return arr;
    };

    const calculateByArray = (array, from, to, origFrom) => {
        let result = null;

        if (array.length > 1) {
            // const daysBetween = getDaysBetween(from, to);
            // console.log(origFrom);
            const daysBetween = getDaysBetween((origFrom ? origFrom : from), to);
            const inYear = daysBetween < 365;

            const firstValue = array[0].rate;
            const lastValue = array[array.length - 1].rate;

            // console.log('Days between: ' + daysBetween + ', in year: ' + inYear + ', firstValue: ' + firstValue + ', lastValue: ' + lastValue);

            const ratio = lastValue / firstValue;

            if (inYear) {

                result = (ratio - 1) /** (365 / daysBetween)*/;

            } else {

                result = Math.pow(ratio, (365 / daysBetween)) - 1;

            }

            // console.log('Result: ' + result);

            return { 'value': result, 'meta': { 'daysBetween': daysBetween, 'inYear': inYear, 'firstValue': firstValue, 'lastValue': lastValue, 'from': from, 'to': to } };

        }

        return { 'value': null };
    };

    const getDaysBetween = (from, to) => {
        var firstDate = moment(from);
        var lastDate = moment(to);

        return lastDate.diff(firstDate, 'days', false);
    };

    const getDaysBetweenByArray = (array) => {
        if (array.length > 1) {
            var firstDate = moment(new Date(array[0].date));
            var lastDate = moment(new Date(array[array.length - 1].date));

            return lastDate.diff(firstDate, 'days', false);
        }
        return 0;
    };

    return (
        <div className='flex flex-col'>

            <div className='w-full overflow-x-auto'>
                <Table className='min-w-[1080px]' data={{ 'config': { 'fields': getTableFields(_data.fundClosedYearPerformance), 'reverseBackground': true, 'rowSeparation': false }, 'list': getTableData(_data.fundClosedYearPerformance) }} />
            </div>

            <div className='w-full gap-1 md:gap-8 px-8 py-3 flex flex-col md:flex-row md:justify-between text-xs font-medium'>
                <div className=''>Referencianap: <span className='font-bold'>{props.rateDataSum ? moment(props.rateDataSum.closeDate).format('YYYY.MM.DD') : '-'}</span></div>
                <div className=''>* Az éves és az éven túli hozamok évesítettek. Az évesítésénél a hozamokat az eltelt napok számával arányosítjuk.</div>
            </div>

        </div>
    );
}

export function FastFundSelect(props) {
    const [fund, setFund] = useState('');

    let _data = {
        list: getValueFromParameterMap(getWebsite().globalContent, 'fund-fast-list') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'fund-fast-list'), 'list') && getValueFromParameterMap(getValueFromParameterMap(getWebsite().globalContent, 'fund-fast-list'), 'list').contentList,
    };

    const getFundLink = (fundId) => {
        for (let i = 0; i < _data.list.length; i++) {
            if (_data.list[i].id == fundId) {
                return getValueFromParameterMap(_data.list[i], 'page');
            }
        }
        return null;
    };

    return (
        <div to={props.path} className={'flex flex-col gap-10 ' + (props.className || '')}>
            <div className='font-display md:font-sans text-3xl md:text-lg md:font-medium px-4 md:px-0'>Befektetési alap</div>
            <div className='flex'>
                <div className='relative flex-1'>
                    <select className='w-full generic-light-select' value={fund} onChange={(e) => setFund(e.target.value)}>
                        <option value='' disabled selected hidden>Befektetési alap</option>
                        {_data.list && _data.list.map((item, index) => <option key={item.id} value={item.id} className='generic-select-option'>{getValueFromParameterMap(item, 'name')}</option>)}
                    </select>
                    <div className='absolute top-0 right-0 -mt-px h-full w-4 bg-gradient-to-r from-transparent to-secondary z-10'></div>
                </div>
                <Link to={getFundLink(fund)} className={'flex items-center justify-center h-full aspect-square hover:bg-transparent border  transition  ' + (getFundLink(fund) ? 'bg-accent border-accent text-secondary hover:text-accent' : ' border-white/10 text-white/10')}>
                    <HiOutlineChevronRight className='text-lg' />
                </Link>
            </div>
        </div>
    );
}

function RateCalculator(props) {
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        legacyFundId: getValueFromParameterMap(props.data, 'legacy-fund-id'),
        legacyFundPassword: getValueFromParameterMap(props.data, 'legacy-fund-password'),
    }

    return (
        <div className='flex flex-col gap-8'>

            <div className='flex flex-col'>

                <div className='flex flex-col gap-6 floating-card-padding'>
                    <div className='floating-card-title'>Hozamkalkulátor</div>
                    <div className='text-xs'>Adja meg a befektetés kezdő-, és végdátumát a legördülő menük segítségével. Kattintson a „Számítás” gombra ahhoz, hogy megismerhesse az adott időszak hozamadatait! Az „Export” gomb segítségével a lekérdezett időszakra vonatkozó információkat excel formátumban lementheti számítógépére.<br /><br />A Hozamkalkulátor segítségével kapott adatok kizárólag a tájékoztatás célját szolgálják, nem tekinthetők befektetési tanácsadásnak, ajánlattételnek,  vagy a befektetési alapra vonatkozó számlakivonatnak.</div>
                </div>

                <div className='floating-card-padding pt-0 bg-primary shadow-3xl'>
                    <Input
                        fundId={_data.legacyFundId}
                        passwordNeeded={_data.legacyFundPassword === null ? false : true}
                        onCalculate={(params) => calculate(params, props.data, (error) => { setError(error) }, (_result) => { setResult({ 'fund': _data.legacyFundId, 'fundData': props.data, 'list': _result }) })}
                        onExport={(params) => { _export(params) }}
                        error={error} />
                </div>

                {result && <div className='w-full flex flex-col items-center md:px-site bg-neutral-50 transition-all'>

                    <div className='w-full max-w-boxed md:p-8'>

                        <Result fund={'hello'} result={result} />

                    </div>

                </div>}

            </div>

        </div>
    );
}