import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieModal from 'components/CookieModal';

import MainPage from 'pages/MainPage';
import PremiumMainPage from 'pages/PremiumMainPage';
import FundListPage from 'pages/FundListPage';
import FundPage from 'pages/FundPage';
import CalculatorPage from 'pages/CalculatorPage';
import PublicationListPage from 'pages/PublicationListPage';
import DocumentDownloadPage from 'pages/DocumentDownloadPage';
import ContentPage from 'pages/ContentPage';
import JobListPage from 'pages/JobListPage';
import NewsListPage from 'pages/NewsListPage';
import ContactPage from 'pages/ContactPage';
import AssetManagementPage from 'pages/AssetManagementPage';
import SustainabilityPage from 'pages/SustainabilityPage';
import ManagementPage from 'pages/ManagementPage';
import AwardsPage from 'pages/AwardsPage';
import GlossaryPage from 'pages/GlossaryPage';
import Error404Page from 'pages/Error404Page';

import CmsFrontendControl from 'services/CmsFrontendControl';
import { setWebsite } from 'utils/WebsiteUtils';
import ScrollToTop from 'utils/ScrollToTop';
import TempRedirectHotfix from 'utils/TempRedirectHotfix';
import WebsiteContextProvider from 'providers/WebsiteContextProvider';

const pageDictionary = {
    'main-page': { page: <MainPage />, templateProps: {} },
    'premium-main-page': { page: <PremiumMainPage />, templateProps: {} },
    'content': { page: <ContentPage />, templateProps: {} },
    'fund-list': { page: <FundListPage />, templateProps: {} },
    'publication-list': { page: <PublicationListPage />, templateProps: {} },
    'document-download': { page: <DocumentDownloadPage />, templateProps: {} },
    'fund': { page: <FundPage />, templateProps: {} },
    'calculator': { page: <CalculatorPage />, templateProps: {} },
    'job-list': { page: <JobListPage />, templateProps: {} },
    'news-list': { page: <NewsListPage />, templateProps: {} },
    'contact': { page: <ContactPage />, templateProps: {} },
    'asset-management': { page: <AssetManagementPage />, templateProps: {} },
    'sustainability': { page: <SustainabilityPage />, templateProps: {} },
    'management': { page: <ManagementPage />, templateProps: {} },
    'awards': { page: <AwardsPage />, templateProps: {} },
    'glossary': { page: <GlossaryPage />, templateProps: {} },
    '404': { page: <Error404Page />, templateProps: {} },
};

const pageKey404 = 'egyszeru-oldal';

const createRoutes = (data) => {
    let _routes = [];
    if (data.mainPage)
        _routes.push({ path: '/', element: React.cloneElement(pageDictionary[data.mainPage.pageTemplate].page, { pageKey: data.mainPage.pageKey, templateProps: pageDictionary[data.mainPage.pageTemplate].templateProps }) });
    for (let key in data.sitemap)
        if (data.sitemap[key].pageTemplate && pageDictionary[data.sitemap[key].pageTemplate])
            _routes.push({ path: key, element: React.cloneElement(pageDictionary[data.sitemap[key].pageTemplate].page, { pageKey: data.sitemap[key].pageKey, templateProps: pageDictionary[data.sitemap[key].pageTemplate].templateProps }) });
        else
            console.log('[Missing template] ' + (data.sitemap[key].pageTemplate ? data.sitemap[key].pageTemplate : 'No template defined.') + ' [for] ' + key);
    _routes.push({ path: '*', element: React.cloneElement(pageDictionary['404'].page, { pageKey: pageKey404 }) });
    return _routes;
}

export default function NBossCMSClient(props) {
    const [website, _setWebsite] = useState(null);
    const [routes, setRoutes] = useState(null);

    const [cookieConsentModalVisible, setCookieConsentModalVisibl] = useState(false);

    useEffect(() => {
        if (!website && props.domainName)
            CmsFrontendControl.getWebsite(props.domainName,/*, window.location.pathname*/null).then(result => _setWebsite(result));
    }, [website, props.domainName]);

    useEffect(() => {
        if (website !== null) {
            setWebsite(website);
            setRoutes(createRoutes(website))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website]);

    if (website && routes) {
        return (
            <WebsiteContextProvider>
                <Router /*basename={'premium-vagyonkezeles'}*/>
                    <div className='w-full min-h-screen flex flex-col overflow-hidden'>

                        <Header />

                        <div className='relative w-full flex flex-1 flex-col items-center bg-primary'>
                            <Routes>{routes.map((route, index) => (<Route key={route.path} exact path={route.path} element={route.element} />))}</Routes>
                            <ScrollToTop />
                            <TempRedirectHotfix />
                        </div>

                        <Footer className='w-full' />

                        <CookieModal />

                        {/* <CookieModal /> */}

                    </div>
                </Router>
            </WebsiteContextProvider>
        );
    } else {
        return (
            <div className='w-full min-h-screen flex flex-col justify-center items-center gap-4'>
                {/* <Logo /> */}
            </div>
        );
    }
}

export function NBossCMSPage(props) {
    useEffect(() => {
        CmsFrontendControl.getPage(props.pageprops.pageKey).then(result => { props.pageDataReady ? props.pageDataReady(result) : console.log('No pageDataReady callback defined.') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pageprops.pageKey]);

    return (<motion.div
        key={props.pageprops.pageKey}
        className={'flex-1 ' + (props.className || '')}
        transition={{ duration: .3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}>
        {props.children}
    </motion.div>);
}

export const getParameterEndMatch = (data, valueKey, end) => {
    if (data && valueKey && end)
        for (let key in data.parameterMap)
            if (key.endsWith(end))
                return data.parameterMap[key][valueKey];
}

export const getValueFromParameterMap = (data, key) => {
    if (data && key && data.parameterMap && data.parameterMap[key])
        return data.parameterMap[key].value;
    return null;
};