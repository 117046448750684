import { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { useWebsite } from 'providers/WebsiteContextProvider';
import { MdOutlineEmail } from "react-icons/md";

export default function AssetManagementPage(props) {
    const [page, setPage] = useState(null);
    const [institutionalOpen, setInstitutionalOpen] = useState(false);

    const location = useLocation();
    const institutionalRef = useRef(null);
    const premiumRef = useRef(null);

    const { setHeaderDark } = useWebsite();

    const _data = {
        title: getValueFromParameterMap(page, 'title'),
    }

    useEffect(() => { setHeaderDark(); }, []);

    useLayoutEffect(() => {
        if (location && location.pathname && page)
            if (location.pathname.indexOf('premium-vagyonkezeles') > -1 && premiumRef.current)
                setTimeout(() => window.scrollTo({ top: premiumRef.current.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' }), 0);
        if (location.pathname.indexOf('intezmenyi-vagyonkezeles') > -1)
            setInstitutionalOpen(true);
    }, [location, page]);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={_page => { setPage(_page) }} className='w-full flex flex-col'>

            <div ref={institutionalRef}><InstitutionalRow data={page} open={institutionalOpen} onOpenChange={(open) => setInstitutionalOpen(open)} /></div>

            <div ref={premiumRef}><PremiumRow data={page} /></div>

        </NBossCMSPage>
    );
}

function InstitutionalRow(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'institutional-title'),
        subTitle: getValueFromParameterMap(props.data, 'institutional-sub-title'),
        content: getValueFromParameterMap(props.data, 'institutional-content'),
        managementList: getValueFromParameterMap(props.data, 'institutional-management-list') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'institutional-management-list'), 'management-person-list').contentList,
    }

    return (
        <Row className='text-white py-page-title' title={_data.title} lead={_data.lead} subTitle={_data.subTitle} content={_data.content} openable open={props.open} onOpenChange={props.onOpenChange} openableChildren={
            <div className='flex flex-col gap-12 py-8'>

                <div className='text-xl font-semibold text-accentt'>Amennyiben felkeltettük érdeklődését, további tájékoztatásért kérjük forduljon munkatársainkhoz bizalommal!</div>

                {_data.managementList && <div className='w-full flex flex-col md:flex-row gap-8 xl:gap-8 pr-4'>

                    {_data.managementList.map((item) => <InstitutionalPerson className='flex-1' key={item.id} data={item} />)}

                    {/* <InstitutionalPerson className='flex-1' name='Pozsgai Gábor' position='Értékesítés és terméktámogatás vezető' email='pozsgai.gabor@granitalapkezelo.hu' image='https://granitalapkezelo.hu//diofa-portal/methods/fmFrontendControl/getFile?key=nbNIrQVEGj8J7TMpgLWgpr7ynJCQT0JMt9lJBvJvBuBHDLSNPr' /> */}
                    {/* <InstitutionalPerson className='flex-1' name='Duha Bence' position='Szenior értékesítési és terméktámogatási menedzser' email='duha.bence@granitalapkezelo.hu' image='https://granitalapkezelo.hu//diofa-portal/methods/fmFrontendControl/getFile?key=awy3RIe0rarTmQFfYIkq8Mdkyh69nsXkIHxnFytcsDGyJBtNRb' /> */}

                </div>}

            </div>
        } />
    );
}

function InstitutionalPerson(props) {
    const _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        position: getValueFromParameterMap(props.data, 'position'),
        email: getValueFromParameterMap(props.data, 'email'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
    }


    return (
        <div className={'relative bg-primary-dark aspect-[175/240] mb-4 ' + (props.className || '')}>

            <div className='absolute top-0 left-0 w-full h-full bg-cover bg-top bg-no-repeat mr-4' style={{ backgroundImage: 'url(' + _data.image + ')' }} />

            <div className='w-full md:min-h-[136px] bg-secondary p-4 absolute -bottom-4 -right-4 flex flex-col justify-between gap-4 border-b-4 border-accent'>

                <div className='flex flex-col gap-2'>
                    <div className='text-accent font-bold uppercasee text-xl xl:text-xl font-display'>{_data.name}</div>
                    <div className='text-xs'>{_data.position}</div>
                </div>

                <a className='flex gap-1 items-center text-white hover:text-accent' href={'mailto:' + _data.email}>
                    <MdOutlineEmail className='text-sm' />
                    <div className='text-xs'>{_data.email}</div>
                </a>

            </div>

        </div>
    );
}

function PremiumRow(props) {
    const _data = {
        title: getValueFromParameterMap(props.data, 'premium-title'),
        lead: getValueFromParameterMap(props.data, 'premium-lead'),
        subTitle: getValueFromParameterMap(props.data, 'premium-sub-title'),
        content: getValueFromParameterMap(props.data, 'premium-content'),
    }

    return (
        <Row className='text-premium bg-premium-light py-24 border-b-8 border-premium' title={_data.title} lead={_data.lead} subTitle={_data.subTitle} content={_data.content} bottomChildren={<a href='https://premium-vagyonkezeles.hu/' className='rounded-button-premium-reverse'>További információk</a>} />
    );
}

function Row(props) {
    // const [open, setOpen] = useState(false);

    return (
        <div className={'w-full flex flex-col items-center px-site transition-all ' + (props.className || '')}>

            <div className='w-full max-w-boxed px-content flex flex-col gap-8 md:gap-20'>

                <div className='font-display text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl'>{props.title}</div>

                <div className='w-full flex flex-col gap-4 md:gap-16 xl:gap-20 pl-0 '>

                    {props.lead && <div className='w-full lg:w-[72%] self-end text-sm leading-relaxed pb-4 md:pb-0' dangerouslySetInnerHTML={{ __html: props.lead }} />}

                    <div className='w-full flex flex-col lg:flex-row justify-between gap-4 md:gap-16 xl:gap-20'>
                        <div className='flex-1 flex justify-end'><div className='w-full font-medium 2xl:text-xl'>{props.subTitle}</div></div>
                        <div className={'w-full lg:w-[72%] flex flex-col items-start transition-all ' + (props.openable ? (props.open ? 'gap-8' : 'gap-4') : 'gap-14 md:gap-24')}>

                            <div className={props.openable ? ('relative overflow-hidden transition-all ' + (props.open ? 'h-auto' : 'h-60')) : ''}>

                                <div className='text-sm paragraph-gap leading-relaxed disc-list transition-all' dangerouslySetInnerHTML={{ __html: props.content }} />

                                {props.openableChildren && props.openableChildren}

                                {props.openable && <div className={'absolute left-0 bottom-0 w-full h-[85%] bg-gradient-to-t from-primary via-primary/50 flex items-end justify-center transition-all ' + (props.open ? 'opacity-0 pointer-events-none' : 'opacity-100')} />}

                            </div>

                            {props.bottomChildren && props.bottomChildren}

                            {props.openable && <button className='rounded-button-accent self-center transition-all' onClick={() => props.onOpenChange(!props.open)}>{props.open ? 'Bezárás' : 'Tovább olvasom'}</button>}


                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
}