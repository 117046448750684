import { useEffect } from 'react';
import TagManager from 'react-gtm-module'

import NBossCMSClient from 'cms/NBossCMS.js';
import nBossConnection from 'services/nBossConnection.js';

const tagManagerArgs = { gtmId: process.env.REACT_APP_DOMAIN_NAME === 'premium-vagyonkezeles.hu' ? 'GTM-KDKNV6B3' : 'GTM-WNCK747K' };

TagManager.initialize(tagManagerArgs);

export default function App() {
  useEffect(() => { nBossConnection.setErrorHandler((error) => { alert(error); }); });
  document.title = process.env.REACT_APP_DOMAIN_NAME === 'premium-vagyonkezeles.hu' ? 'GRÁNIT Prémium Vagyonkezelés' : 'GRÁNIT Alapkezelő';
  return (<NBossCMSClient domainName={process.env.REACT_APP_DOMAIN_NAME} />);
}