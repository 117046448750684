import { useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

export default function ScrollToTop({ children }) {
    const { pathname } = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(pathname);
        console.log(searchParams.toString());

        //  let sp= ''
        //         if(searchParams.toString() === '')
        //             console.log('ASD');



        if (pathname.indexOf('%20') > -1 || pathname.indexOf(' ') > -1)
            navigate(pathname.replace('%20', '').replace(' ', '') + (searchParams.toString() !== '' ? ('?' + searchParams.toString()) : ''));
    }, [pathname, searchParams]);

    return children;
}


// export const scrollTop = () => {
//     window.scrollTo({ top: 0, behavior: 'smooth' });
// };
